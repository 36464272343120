html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "BMWTypeNext";
  src: url("./fonts/BMWTypeNextLatin-Bold.otf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "BMWTypeNext";
  src: url("./fonts/BMWTypeNextLatin-Regular.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "BMWTypeNext";
  src: url("./fonts/BMWTypeNextLatin-Thin.otf");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

/* Mini */
@font-face {
  font-family: "MINISansSerif";
  /* src: url("../fonts/MINISerif-Regular_1.ttf"); */
  src: url("./fonts/MINISansSerif-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "MINISansSerif";
  src: url("./fonts/MINISansSerif-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "MINISerif";
  src: url("./fonts/MINISerif-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "MINISerif";
  src: url("./fonts/MINISerif-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Mini Thai */
@font-face {
  font-family: "DBOnuma";
  src: url("./fonts/DB-ONUMA.TTF");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "DBOnuma";
  src: url("./fonts/DB-ONUMA-Bold.TTF");
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Motorrad */
@font-face {
  font-family: "Motorrad";
  src: url("./fonts/BMWMotorrad-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Motorrad";
  src: url("./fonts/BMWMotorrad-Bold.otf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

/* BMW Thai */
@font-face {
  font-family: "BMWThailand";
  src: url("./fonts/BMW\ Thailand\ Regular.ttf");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "BMWThailand";
  src: url("./fonts/BMW\ Thailand\ Bold.ttf");
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
